// Code generated by protoc-gen-ts_proto. DO NOT EDIT.
// versions:
//   protoc-gen-ts_proto  v2.2.5
//   protoc               v5.28.3
// source: go.chromium.org/luci/buildbucket/proto/service_config.proto

/* eslint-disable */
import { BinaryReader, BinaryWriter } from "@bufbuild/protobuf/wire";
import { Duration } from "../../../../google/protobuf/duration.pb";
import { BuilderConfig_CacheEntry } from "./project_config.pb";

export const protobufPackage = "buildbucket";

/**
 * A CustomMetricBase tells the base metric of a given custom metric.
 *
 * Buildbucket will determine what to report to and the annotation for
 * a given CustomMetric, based on the specified metric base. Visit
 * http://shortn/_6bc0aXIouD to find all the supported metric bases.
 */
export enum CustomMetricBase {
  CUSTOM_METRIC_BASE_UNSET = 0,
  /**
   * CUSTOM_METRIC_BASE_CREATED - Build event metrics.
   * A counter metric for Build creation.
   * Default metric field: None.
   */
  CUSTOM_METRIC_BASE_CREATED = 1,
  /**
   * CUSTOM_METRIC_BASE_STARTED - A counter metric for Build start..
   * Default metric field: None.
   */
  CUSTOM_METRIC_BASE_STARTED = 2,
  /**
   * CUSTOM_METRIC_BASE_COMPLETED - A counter metric for Build completion..
   * Default metric field: status.
   */
  CUSTOM_METRIC_BASE_COMPLETED = 3,
  /**
   * CUSTOM_METRIC_BASE_CYCLE_DURATIONS - The time elapsed from the build creation to the completion.
   * Default metric field: status.
   */
  CUSTOM_METRIC_BASE_CYCLE_DURATIONS = 4,
  /**
   * CUSTOM_METRIC_BASE_RUN_DURATIONS - The time elapsed from the build start to the completion.
   * Default metric field: status.
   */
  CUSTOM_METRIC_BASE_RUN_DURATIONS = 5,
  /**
   * CUSTOM_METRIC_BASE_SCHEDULING_DURATIONS - The time elapsed from the build creation to the start.
   * Default metric field: None.
   */
  CUSTOM_METRIC_BASE_SCHEDULING_DURATIONS = 6,
  /**
   * CUSTOM_METRIC_BASE_MAX_AGE_SCHEDULED - Builder metrics.
   * The age of the oldest build that has been scheduled but not started yet
   * (aka age of the oldest pending build).
   * Default metric field: None.
   */
  CUSTOM_METRIC_BASE_MAX_AGE_SCHEDULED = 7,
  /**
   * CUSTOM_METRIC_BASE_COUNT - Number of Builds for each Build status.
   * Default metric field: status.
   */
  CUSTOM_METRIC_BASE_COUNT = 8,
  /**
   * CUSTOM_METRIC_BASE_CONSECUTIVE_FAILURE_COUNT - Number of consecutive failures since the last successful build.
   * Default metric field: status.
   */
  CUSTOM_METRIC_BASE_CONSECUTIVE_FAILURE_COUNT = 9,
  /**
   * CUSTOM_METRIC_BASE_AGE - Age of builds for pending(may be extended to other statuses later) status.
   * Default metric field: status.
   */
  CUSTOM_METRIC_BASE_AGE = 10,
}

export function customMetricBaseFromJSON(object: any): CustomMetricBase {
  switch (object) {
    case 0:
    case "CUSTOM_METRIC_BASE_UNSET":
      return CustomMetricBase.CUSTOM_METRIC_BASE_UNSET;
    case 1:
    case "CUSTOM_METRIC_BASE_CREATED":
      return CustomMetricBase.CUSTOM_METRIC_BASE_CREATED;
    case 2:
    case "CUSTOM_METRIC_BASE_STARTED":
      return CustomMetricBase.CUSTOM_METRIC_BASE_STARTED;
    case 3:
    case "CUSTOM_METRIC_BASE_COMPLETED":
      return CustomMetricBase.CUSTOM_METRIC_BASE_COMPLETED;
    case 4:
    case "CUSTOM_METRIC_BASE_CYCLE_DURATIONS":
      return CustomMetricBase.CUSTOM_METRIC_BASE_CYCLE_DURATIONS;
    case 5:
    case "CUSTOM_METRIC_BASE_RUN_DURATIONS":
      return CustomMetricBase.CUSTOM_METRIC_BASE_RUN_DURATIONS;
    case 6:
    case "CUSTOM_METRIC_BASE_SCHEDULING_DURATIONS":
      return CustomMetricBase.CUSTOM_METRIC_BASE_SCHEDULING_DURATIONS;
    case 7:
    case "CUSTOM_METRIC_BASE_MAX_AGE_SCHEDULED":
      return CustomMetricBase.CUSTOM_METRIC_BASE_MAX_AGE_SCHEDULED;
    case 8:
    case "CUSTOM_METRIC_BASE_COUNT":
      return CustomMetricBase.CUSTOM_METRIC_BASE_COUNT;
    case 9:
    case "CUSTOM_METRIC_BASE_CONSECUTIVE_FAILURE_COUNT":
      return CustomMetricBase.CUSTOM_METRIC_BASE_CONSECUTIVE_FAILURE_COUNT;
    case 10:
    case "CUSTOM_METRIC_BASE_AGE":
      return CustomMetricBase.CUSTOM_METRIC_BASE_AGE;
    default:
      throw new globalThis.Error("Unrecognized enum value " + object + " for enum CustomMetricBase");
  }
}

export function customMetricBaseToJSON(object: CustomMetricBase): string {
  switch (object) {
    case CustomMetricBase.CUSTOM_METRIC_BASE_UNSET:
      return "CUSTOM_METRIC_BASE_UNSET";
    case CustomMetricBase.CUSTOM_METRIC_BASE_CREATED:
      return "CUSTOM_METRIC_BASE_CREATED";
    case CustomMetricBase.CUSTOM_METRIC_BASE_STARTED:
      return "CUSTOM_METRIC_BASE_STARTED";
    case CustomMetricBase.CUSTOM_METRIC_BASE_COMPLETED:
      return "CUSTOM_METRIC_BASE_COMPLETED";
    case CustomMetricBase.CUSTOM_METRIC_BASE_CYCLE_DURATIONS:
      return "CUSTOM_METRIC_BASE_CYCLE_DURATIONS";
    case CustomMetricBase.CUSTOM_METRIC_BASE_RUN_DURATIONS:
      return "CUSTOM_METRIC_BASE_RUN_DURATIONS";
    case CustomMetricBase.CUSTOM_METRIC_BASE_SCHEDULING_DURATIONS:
      return "CUSTOM_METRIC_BASE_SCHEDULING_DURATIONS";
    case CustomMetricBase.CUSTOM_METRIC_BASE_MAX_AGE_SCHEDULED:
      return "CUSTOM_METRIC_BASE_MAX_AGE_SCHEDULED";
    case CustomMetricBase.CUSTOM_METRIC_BASE_COUNT:
      return "CUSTOM_METRIC_BASE_COUNT";
    case CustomMetricBase.CUSTOM_METRIC_BASE_CONSECUTIVE_FAILURE_COUNT:
      return "CUSTOM_METRIC_BASE_CONSECUTIVE_FAILURE_COUNT";
    case CustomMetricBase.CUSTOM_METRIC_BASE_AGE:
      return "CUSTOM_METRIC_BASE_AGE";
    default:
      throw new globalThis.Error("Unrecognized enum value " + object + " for enum CustomMetricBase");
  }
}

/** Schema of settings.cfg file, a service config. */
export interface SettingsCfg {
  /** Swarmbucket settings. */
  readonly swarming: SwarmingSettings | undefined;
  readonly logdog: LogDogSettings | undefined;
  readonly resultdb: ResultDBSettings | undefined;
  readonly experiment: ExperimentSettings | undefined;
  readonly cipd:
    | CipdSettings
    | undefined;
  /**
   * List of Gerrit hosts to force git authentication for.
   *
   * By default public hosts are accessed anonymously, and the anonymous access
   * has very low quota. Context needs to know all such hostnames in advance to
   * be able to force authenticated access to them.
   */
  readonly knownPublicGerritHosts: readonly string[];
  /** List of known backend configurations. */
  readonly backends: readonly BackendSetting[];
  /**
   * A swarming_host -> backend_target map.
   * It's used during the raw swarming to swarming based backend migration to
   * derive a backend config from swarming related configs.
   * So we could control the migration process behind the scene.
   */
  readonly swarmingBackends: { [key: string]: string };
  /**
   * Custom metrics.
   *
   * Global config to register custom build metrics that more than one LUCI
   * projects may report data to.
   * Therefore, the metric names must be unique globally.
   */
  readonly customMetrics: readonly CustomMetric[];
}

export interface SettingsCfg_SwarmingBackendsEntry {
  readonly key: string;
  readonly value: string;
}

/** Backend setting. */
export interface BackendSetting {
  /** Target backend. e.g. "swarming://chromium-swarm". */
  readonly target: string;
  /** hostname for the target backend. e.g. chromium-swarm.appspot.com */
  readonly hostname: string;
  /**
   * The associated UpdateBuildTask pubsub id for this task backend.
   * This must be set in order for buildbucket to recieve task updates and send
   * a pubsup topic that the task backend can use to send updates to.
   * This id is provided by the backend owner, but added to buildbucket's service
   * config by the buildbucket team.
   * Buildbucket will subscribe to the pubsub subscription, which itself is
   * subscribed to the topic that task backends send messages to.
   * i.e.
   *     For this pubsub subscription:
   *         project/cr-buildbucket/subscriptions/chormium-swarm-backend
   *     and topic:
   *         project/cr-buildbucket/topics/chormium-swarm-backend
   *     The pubsub_id would be:
   *         chormium-swarm-backend
   *
   * DEPRECATED: please set into `full_mode` field.
   *
   * @deprecated
   */
  readonly pubsubId: string;
  /**
   * DEPRECATED: please set into `full_mode` field.
   *
   * @deprecated
   */
  readonly buildSyncSetting: BackendSetting_BuildSyncSetting | undefined;
  readonly fullMode?: BackendSetting_FullMode | undefined;
  readonly liteMode?:
    | BackendSetting_LiteMode
    | undefined;
  /**
   * Maximum time for Buildbucket to retry creating a task.
   *
   * Default timeout is 10 minutes.
   *
   * If the timeout is reached, the build is marked as INFRA_FAILURE status.
   */
  readonly taskCreatingTimeout: Duration | undefined;
}

/** Setting for the fetch tasks cron job. */
export interface BackendSetting_BuildSyncSetting {
  /**
   * Number of shards for Buildbucket to save the update_time of the builds on
   * this backend.
   *
   * In Build model, we need to keep an index on the build update time in order
   * to get all builds it haven't heard for a while.
   * To avoid hotspotting, a new computed property NextBackendSyncTime is added
   * for indexing, with format
   * <backend_target>--<project>--<shard index>--<update_time>.
   *
   * Because some backends serves significantly larger amount of builds than
   * other backends, we need different number of shards for each backend.
   *
   * Must be greater than or equal to 0, while 0 effectively means 1 shard.
   *
   * Note: when updating this field, the new number MUST be greater than the
   * old one.
   */
  readonly shards: number;
  /**
   * How often should a incomplete build get updated. If Buildbucket doesn't
   * hear from this build for sync_interval, it will sync it with its backend
   * task.
   *
   * Buildbucket runs a cron job to find builds to sync every minute, so
   * sync_interval_seconds must be greater than or equal to 60.
   *
   * If unset, a default threshold of 5min will be applied.
   */
  readonly syncIntervalSeconds: number;
}

/**
 * The corresponding backend should be a full-featured `TaskBackend`
 * implementation.
 */
export interface BackendSetting_FullMode {
  /**
   * The associated UpdateBuildTask pubsub id for this task backend.
   * This must be set in order for buildbucket to recieve task updates and send
   * a pubsup topic that the task backend can use to send updates to.
   * This id is provided by the backend owner, but added to buildbucket's service
   * config by the buildbucket team.
   * Buildbucket will subscribe to the pubsub subscription, which itself is
   * subscribed to the topic that task backends send messages to.
   * i.e.
   *     For this pubsub subscription:
   *         project/cr-buildbucket/subscriptions/chormium-swarm-backend
   *     and topic:
   *         project/cr-buildbucket/topics/chormium-swarm-backend
   *     The pubsub_id would be:
   *         chormium-swarm-backend
   */
  readonly pubsubId: string;
  /** The setting for syncing backend tasks. */
  readonly buildSyncSetting:
    | BackendSetting_BuildSyncSetting
    | undefined;
  /**
   * If set to true, visiting "cr-buildbucket.appspot.com/build/<build_id>"
   * will be redirected to the corresponding task page instead of the Milo
   * build page.
   *
   * To visit the Milo build page with this configuration on, please use
   * "ci.chromium.org/b/<build_id>".
   */
  readonly redirectToTaskPage: boolean;
  /**
   * If set to true, Buildbucket will set the final build status to SUCCESS
   * if the backend task is SUCCESS, regardless the build's output status.
   *
   * For standard backends like Swarming, the build should always finish
   * before the backend task - unless the task crashes or being killed.
   * So in the case that build output status is not ended, Buildbucket
   * sets the build's final status with the task's status -if it ends
   * with a non SUCCESS status. But if the task has
   * ended with a SUCCESS status while the build is still running, Buildbucket
   * considers that impossible and sets the build to INFRA_FAILURE.
   *
   * But for other backends e.g. Skia, we cannot have the assumption that
   * build always finishes before task. And to them a successful task always
   * means a successful build.
   */
  readonly succeedBuildIfTaskIsSucceeded: boolean;
}

/**
 * The corresponding backend should be a `TaskBackendLite` implementation,
 * where it doesn't support the task update and sync.
 */
export interface BackendSetting_LiteMode {
}

/** Swarmbucket settings. */
export interface SwarmingSettings {
  /** Swarmbucket build URLs will point to this Milo instance. */
  readonly miloHostname: string;
  /**
   * These caches are available to all builders implicitly.
   * A builder may override a cache specified here.
   */
  readonly globalCaches: readonly BuilderConfig_CacheEntry[];
  /**
   * Packages available to the user executable in $PATH.
   * Installed in "{TASK_RUN_DIR}/cipd_bin_packages".
   * "{TASK_RUN_DIR}/cipd_bin_packages" and
   * "{TASK_RUN_DIR}/cipd_bin_packages/bin" are prepended to $PATH.
   */
  readonly userPackages: readonly SwarmingSettings_Package[];
  /**
   * Package of buildbucket agent,
   * https://chromium.googlesource.com/infra/luci/luci-go/+/HEAD/buildbucket/cmd/bbagent
   * used to run LUCI executables.
   */
  readonly bbagentPackage:
    | SwarmingSettings_Package
    | undefined;
  /** CIPD package of kitchen binary. DEPRECATED. TODO(nodir): remove. */
  readonly kitchenPackage:
    | SwarmingSettings_Package
    | undefined;
  /**
   * Package of alternative buildbucket agent sources.
   * They should only be used in certain situations (i.e. in an experiment),
   * so they should have constraints on either omit_on_experiment
   * or include_on_experiment.
   */
  readonly alternativeAgentPackages: readonly SwarmingSettings_Package[];
  /**
   * Packages for bbagent to use.
   * Installed in "{TASK_RUN_DIR}/bbagent_utility_packages".
   */
  readonly bbagentUtilityPackages: readonly SwarmingSettings_Package[];
}

/** CIPD package. Does not specify installation path. */
export interface SwarmingSettings_Package {
  /** CIPD package name, e.g. "infra/python/cpython/${platform}" */
  readonly packageName: string;
  /**
   * CIPD instance version, e.g. "version:2.7.15.chromium14".
   * Used for non-canary builds.
   */
  readonly version: string;
  /**
   * CIPD instance version for canary builds.
   * Defaults to version.
   */
  readonly versionCanary: string;
  /** Include in builders matching the predicate. */
  readonly builders:
    | BuilderPredicate
    | undefined;
  /**
   * Subdirectory to install the package into, relative to the installation
   * root. Useful if installing two packages at the same root would conflict.
   */
  readonly subdir: string;
  /** Omit this package from the build having any of these experiments. */
  readonly omitOnExperiment: readonly string[];
  /**
   * If non-empty, include this package only on builds which have any of these
   * experiments set. `omit_on_experiment` takes precedence if an experiment
   * is in both of these lists.
   */
  readonly includeOnExperiment: readonly string[];
}

export interface LogDogSettings {
  /** Hostname of the LogDog instance to use, e.g. "logs.chromium.org". */
  readonly hostname: string;
}

/** ExperimentSettings controls all well-known global experiment values. */
export interface ExperimentSettings {
  readonly experiments: readonly ExperimentSettings_Experiment[];
}

export interface ExperimentSettings_Experiment {
  /** The name of the global experiment. */
  readonly name: string;
  /**
   * The default_value (% chance, 0 - 100) of the global experiment.
   *
   * This must be greater than or equal to minimum_value.
   */
  readonly defaultValue: number;
  /**
   * The minimum_value (% chance, 0 - 100) of the global experiment.
   *
   * This will override lower Builder-defined experiment values.
   */
  readonly minimumValue: number;
  /**
   * Allows temporary exclusion of builders from the experiment.
   * Each line here should have a corresponding bug to remove the exclusion.
   *
   * If a builder is excluded from this experiment, it acts as though
   * default_value and minimum_value are both 0.
   */
  readonly builders:
    | BuilderPredicate
    | undefined;
  /**
   * If this is true it means that the experiment has no effect, and is safe
   * to stop setting in user configs. Additionally, Buildbucket will stop
   * setting this experiment negatively on Builds.
   *
   * When removing a global experiment, set this to true rather than removing
   * the experiment entirely, because Buildbucket still needs this to permit
   * (and ignore) user configs which still mention reserved experiments (e.g.
   * if we have "luci.something" which someone explicitly specifies, and we
   * ramp it to 100% and remove it from the global spec, Buildbucket will
   * start complaining that users are using a reserved experiment name, rather
   * than just ignoring it).
   *
   * If inactive experiments appear in user configurations, it may cause
   * warnings to be printed e.g. at config validation time and/or on the LUCI
   * UI, etc.
   */
  readonly inactive: boolean;
}

/** A predicate for a builder. */
export interface BuilderPredicate {
  /**
   * OR-connected list of regular expressions for a string
   * "{project}/{bucket}/{builder}".
   * Each regex is wrapped in ^ and $ automatically.
   * Examples:
   *
   *   # All builders in "chromium" project
   *   regex: "chromium/.+"
   *   # A specific builder.
   *   regex: "infra/ci/infra-continuous-trusty-64"
   *
   * Defaults to [".*"].
   */
  readonly regex: readonly string[];
  /** Like regex field, but negation. Negation always wins. */
  readonly regexExclude: readonly string[];
}

export interface ResultDBSettings {
  /** Hostname of the ResultDB instance to use, e.g. "results.api.cr.dev". */
  readonly hostname: string;
}

export interface CipdSettings {
  /**
   * default CIPD server to use for this setting configuration,
   * e.g. "chrome-infra-packages.appspot.com".
   */
  readonly server: string;
  /** Source of the cipd package itself */
  readonly source: CipdSettings_Source | undefined;
}

export interface CipdSettings_Source {
  /** CIPD package name, e.g. "infra/tools/cipd/${platform}" */
  readonly packageName: string;
  /**
   * CIPD instance version, e.g. "L34sd94gsdgs4gsd" or some hash.
   * Used for non-canary builds.
   */
  readonly version: string;
  /**
   * CIPD instance version for canary builds.
   * Defaults to version.
   */
  readonly versionCanary: string;
}

/**
 * CustomMetric is a metric object that collects a series of data
 * exported by a given metric class.
 *
 * All metrics are using luci.Builder schema at https://source.chromium.org/chromium/infra/infra/+/main:go/src/go.chromium.org/luci/buildbucket/metrics/builder.go;l=26-48;drc=b77d0c2bd4bbb536f8e349e993e3ca18818c51e7.
 *
 * Builders can then reference a custom metric by name to report their build
 * metrics.
 *
 * Note: if you ever want to remove a custom metric, the best practice is to
 * remove its references from builders first, then remove it from the service
 * config. Otherwise any change to a builder config refers to this custom metric
 * would fail validation (for using an unregistered custom metric). But this
 * is not a hard error for build creation or event reporting: missing metric
 * would just be silently ignored.
 */
export interface CustomMetric {
  /**
   * Name of the metric.
   *
   * Each builder that uses this metric needs to add a CustomMetricDefinition entry in
   * their config, with the same name as here.
   *
   * Must be unique globally across all LUCI projects.
   * Must conform to ^(/[a-zA-Z0-9_-]+)+$ (See
   * https://source.chromium.org/chromium/infra/infra/+/main:go/src/go.chromium.org/luci/common/tsmon/registry/registry.go;l=33;drc=b77d0c2bd4bbb536f8e349e993e3ca18818c51e7).
   */
  readonly name: string;
  /** Description of the metric. */
  readonly description: string;
  /**
   * Metric field names in addtion to the default ones.
   *
   * Default metric fields can be found in CustomMetricBase comments.
   *
   * Each builder that uses this metric must specify how to populate each
   * field's value in their configurations, see CustomMetricDefinition.Fields.
   *
   * Must not have duplicates.
   *
   * Custom builder metric cannot have any custom fields in addition to their
   * defaults, i.e. if it's one of
   * * CUSTOM_METRIC_BASE_MAX_AGE_SCHEDULED
   * * CUSTOM_METRIC_BASE_COUNT
   * * CUSTOM_METRIC_BASE_CONSECUTIVE_FAILURE_COUNT
   *
   * The value type of all metric fields is always string.
   * Each Field must conform to ^[A-Za-z_][A-Za-z0-9_]*$ (See
   * https://source.chromium.org/chromium/infra/infra/+/main:go/src/go.chromium.org/luci/common/tsmon/registry/registry.go;l=34;drc=b77d0c2bd4bbb536f8e349e993e3ca18818c51e7).
   *
   * Note that the possible values of each field should be bounded.
   * So below fields should not be included:
   * * build id
   * * any timestamp (e.g. build's creation time)
   * * any markdown strings (e.g. build's summary_markdown)
   * * any log strings (e.g. build's output logs)
   * * any PII
   * * build's gitiles commit hash
   * * build's gerrit change number
   * * etc
   */
  readonly extraFields: readonly string[];
  readonly metricBase?: CustomMetricBase | undefined;
}

function createBaseSettingsCfg(): SettingsCfg {
  return {
    swarming: undefined,
    logdog: undefined,
    resultdb: undefined,
    experiment: undefined,
    cipd: undefined,
    knownPublicGerritHosts: [],
    backends: [],
    swarmingBackends: {},
    customMetrics: [],
  };
}

export const SettingsCfg: MessageFns<SettingsCfg> = {
  encode(message: SettingsCfg, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.swarming !== undefined) {
      SwarmingSettings.encode(message.swarming, writer.uint32(10).fork()).join();
    }
    if (message.logdog !== undefined) {
      LogDogSettings.encode(message.logdog, writer.uint32(18).fork()).join();
    }
    if (message.resultdb !== undefined) {
      ResultDBSettings.encode(message.resultdb, writer.uint32(34).fork()).join();
    }
    if (message.experiment !== undefined) {
      ExperimentSettings.encode(message.experiment, writer.uint32(42).fork()).join();
    }
    if (message.cipd !== undefined) {
      CipdSettings.encode(message.cipd, writer.uint32(50).fork()).join();
    }
    for (const v of message.knownPublicGerritHosts) {
      writer.uint32(26).string(v!);
    }
    for (const v of message.backends) {
      BackendSetting.encode(v!, writer.uint32(58).fork()).join();
    }
    Object.entries(message.swarmingBackends).forEach(([key, value]) => {
      SettingsCfg_SwarmingBackendsEntry.encode({ key: key as any, value }, writer.uint32(66).fork()).join();
    });
    for (const v of message.customMetrics) {
      CustomMetric.encode(v!, writer.uint32(74).fork()).join();
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): SettingsCfg {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSettingsCfg() as any;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 10) {
            break;
          }

          message.swarming = SwarmingSettings.decode(reader, reader.uint32());
          continue;
        }
        case 2: {
          if (tag !== 18) {
            break;
          }

          message.logdog = LogDogSettings.decode(reader, reader.uint32());
          continue;
        }
        case 4: {
          if (tag !== 34) {
            break;
          }

          message.resultdb = ResultDBSettings.decode(reader, reader.uint32());
          continue;
        }
        case 5: {
          if (tag !== 42) {
            break;
          }

          message.experiment = ExperimentSettings.decode(reader, reader.uint32());
          continue;
        }
        case 6: {
          if (tag !== 50) {
            break;
          }

          message.cipd = CipdSettings.decode(reader, reader.uint32());
          continue;
        }
        case 3: {
          if (tag !== 26) {
            break;
          }

          message.knownPublicGerritHosts.push(reader.string());
          continue;
        }
        case 7: {
          if (tag !== 58) {
            break;
          }

          message.backends.push(BackendSetting.decode(reader, reader.uint32()));
          continue;
        }
        case 8: {
          if (tag !== 66) {
            break;
          }

          const entry8 = SettingsCfg_SwarmingBackendsEntry.decode(reader, reader.uint32());
          if (entry8.value !== undefined) {
            message.swarmingBackends[entry8.key] = entry8.value;
          }
          continue;
        }
        case 9: {
          if (tag !== 74) {
            break;
          }

          message.customMetrics.push(CustomMetric.decode(reader, reader.uint32()));
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): SettingsCfg {
    return {
      swarming: isSet(object.swarming) ? SwarmingSettings.fromJSON(object.swarming) : undefined,
      logdog: isSet(object.logdog) ? LogDogSettings.fromJSON(object.logdog) : undefined,
      resultdb: isSet(object.resultdb) ? ResultDBSettings.fromJSON(object.resultdb) : undefined,
      experiment: isSet(object.experiment) ? ExperimentSettings.fromJSON(object.experiment) : undefined,
      cipd: isSet(object.cipd) ? CipdSettings.fromJSON(object.cipd) : undefined,
      knownPublicGerritHosts: globalThis.Array.isArray(object?.knownPublicGerritHosts)
        ? object.knownPublicGerritHosts.map((e: any) => globalThis.String(e))
        : [],
      backends: globalThis.Array.isArray(object?.backends)
        ? object.backends.map((e: any) => BackendSetting.fromJSON(e))
        : [],
      swarmingBackends: isObject(object.swarmingBackends)
        ? Object.entries(object.swarmingBackends).reduce<{ [key: string]: string }>((acc, [key, value]) => {
          acc[key] = String(value);
          return acc;
        }, {})
        : {},
      customMetrics: globalThis.Array.isArray(object?.customMetrics)
        ? object.customMetrics.map((e: any) => CustomMetric.fromJSON(e))
        : [],
    };
  },

  toJSON(message: SettingsCfg): unknown {
    const obj: any = {};
    if (message.swarming !== undefined) {
      obj.swarming = SwarmingSettings.toJSON(message.swarming);
    }
    if (message.logdog !== undefined) {
      obj.logdog = LogDogSettings.toJSON(message.logdog);
    }
    if (message.resultdb !== undefined) {
      obj.resultdb = ResultDBSettings.toJSON(message.resultdb);
    }
    if (message.experiment !== undefined) {
      obj.experiment = ExperimentSettings.toJSON(message.experiment);
    }
    if (message.cipd !== undefined) {
      obj.cipd = CipdSettings.toJSON(message.cipd);
    }
    if (message.knownPublicGerritHosts?.length) {
      obj.knownPublicGerritHosts = message.knownPublicGerritHosts;
    }
    if (message.backends?.length) {
      obj.backends = message.backends.map((e) => BackendSetting.toJSON(e));
    }
    if (message.swarmingBackends) {
      const entries = Object.entries(message.swarmingBackends);
      if (entries.length > 0) {
        obj.swarmingBackends = {};
        entries.forEach(([k, v]) => {
          obj.swarmingBackends[k] = v;
        });
      }
    }
    if (message.customMetrics?.length) {
      obj.customMetrics = message.customMetrics.map((e) => CustomMetric.toJSON(e));
    }
    return obj;
  },

  create(base?: DeepPartial<SettingsCfg>): SettingsCfg {
    return SettingsCfg.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<SettingsCfg>): SettingsCfg {
    const message = createBaseSettingsCfg() as any;
    message.swarming = (object.swarming !== undefined && object.swarming !== null)
      ? SwarmingSettings.fromPartial(object.swarming)
      : undefined;
    message.logdog = (object.logdog !== undefined && object.logdog !== null)
      ? LogDogSettings.fromPartial(object.logdog)
      : undefined;
    message.resultdb = (object.resultdb !== undefined && object.resultdb !== null)
      ? ResultDBSettings.fromPartial(object.resultdb)
      : undefined;
    message.experiment = (object.experiment !== undefined && object.experiment !== null)
      ? ExperimentSettings.fromPartial(object.experiment)
      : undefined;
    message.cipd = (object.cipd !== undefined && object.cipd !== null)
      ? CipdSettings.fromPartial(object.cipd)
      : undefined;
    message.knownPublicGerritHosts = object.knownPublicGerritHosts?.map((e) => e) || [];
    message.backends = object.backends?.map((e) => BackendSetting.fromPartial(e)) || [];
    message.swarmingBackends = Object.entries(object.swarmingBackends ?? {}).reduce<{ [key: string]: string }>(
      (acc, [key, value]) => {
        if (value !== undefined) {
          acc[key] = globalThis.String(value);
        }
        return acc;
      },
      {},
    );
    message.customMetrics = object.customMetrics?.map((e) => CustomMetric.fromPartial(e)) || [];
    return message;
  },
};

function createBaseSettingsCfg_SwarmingBackendsEntry(): SettingsCfg_SwarmingBackendsEntry {
  return { key: "", value: "" };
}

export const SettingsCfg_SwarmingBackendsEntry: MessageFns<SettingsCfg_SwarmingBackendsEntry> = {
  encode(message: SettingsCfg_SwarmingBackendsEntry, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.key !== "") {
      writer.uint32(10).string(message.key);
    }
    if (message.value !== "") {
      writer.uint32(18).string(message.value);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): SettingsCfg_SwarmingBackendsEntry {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSettingsCfg_SwarmingBackendsEntry() as any;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 10) {
            break;
          }

          message.key = reader.string();
          continue;
        }
        case 2: {
          if (tag !== 18) {
            break;
          }

          message.value = reader.string();
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): SettingsCfg_SwarmingBackendsEntry {
    return {
      key: isSet(object.key) ? globalThis.String(object.key) : "",
      value: isSet(object.value) ? globalThis.String(object.value) : "",
    };
  },

  toJSON(message: SettingsCfg_SwarmingBackendsEntry): unknown {
    const obj: any = {};
    if (message.key !== "") {
      obj.key = message.key;
    }
    if (message.value !== "") {
      obj.value = message.value;
    }
    return obj;
  },

  create(base?: DeepPartial<SettingsCfg_SwarmingBackendsEntry>): SettingsCfg_SwarmingBackendsEntry {
    return SettingsCfg_SwarmingBackendsEntry.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<SettingsCfg_SwarmingBackendsEntry>): SettingsCfg_SwarmingBackendsEntry {
    const message = createBaseSettingsCfg_SwarmingBackendsEntry() as any;
    message.key = object.key ?? "";
    message.value = object.value ?? "";
    return message;
  },
};

function createBaseBackendSetting(): BackendSetting {
  return {
    target: "",
    hostname: "",
    pubsubId: "",
    buildSyncSetting: undefined,
    fullMode: undefined,
    liteMode: undefined,
    taskCreatingTimeout: undefined,
  };
}

export const BackendSetting: MessageFns<BackendSetting> = {
  encode(message: BackendSetting, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.target !== "") {
      writer.uint32(10).string(message.target);
    }
    if (message.hostname !== "") {
      writer.uint32(18).string(message.hostname);
    }
    if (message.pubsubId !== "") {
      writer.uint32(26).string(message.pubsubId);
    }
    if (message.buildSyncSetting !== undefined) {
      BackendSetting_BuildSyncSetting.encode(message.buildSyncSetting, writer.uint32(34).fork()).join();
    }
    if (message.fullMode !== undefined) {
      BackendSetting_FullMode.encode(message.fullMode, writer.uint32(42).fork()).join();
    }
    if (message.liteMode !== undefined) {
      BackendSetting_LiteMode.encode(message.liteMode, writer.uint32(50).fork()).join();
    }
    if (message.taskCreatingTimeout !== undefined) {
      Duration.encode(message.taskCreatingTimeout, writer.uint32(58).fork()).join();
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): BackendSetting {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseBackendSetting() as any;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 10) {
            break;
          }

          message.target = reader.string();
          continue;
        }
        case 2: {
          if (tag !== 18) {
            break;
          }

          message.hostname = reader.string();
          continue;
        }
        case 3: {
          if (tag !== 26) {
            break;
          }

          message.pubsubId = reader.string();
          continue;
        }
        case 4: {
          if (tag !== 34) {
            break;
          }

          message.buildSyncSetting = BackendSetting_BuildSyncSetting.decode(reader, reader.uint32());
          continue;
        }
        case 5: {
          if (tag !== 42) {
            break;
          }

          message.fullMode = BackendSetting_FullMode.decode(reader, reader.uint32());
          continue;
        }
        case 6: {
          if (tag !== 50) {
            break;
          }

          message.liteMode = BackendSetting_LiteMode.decode(reader, reader.uint32());
          continue;
        }
        case 7: {
          if (tag !== 58) {
            break;
          }

          message.taskCreatingTimeout = Duration.decode(reader, reader.uint32());
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): BackendSetting {
    return {
      target: isSet(object.target) ? globalThis.String(object.target) : "",
      hostname: isSet(object.hostname) ? globalThis.String(object.hostname) : "",
      pubsubId: isSet(object.pubsubId) ? globalThis.String(object.pubsubId) : "",
      buildSyncSetting: isSet(object.buildSyncSetting)
        ? BackendSetting_BuildSyncSetting.fromJSON(object.buildSyncSetting)
        : undefined,
      fullMode: isSet(object.fullMode) ? BackendSetting_FullMode.fromJSON(object.fullMode) : undefined,
      liteMode: isSet(object.liteMode) ? BackendSetting_LiteMode.fromJSON(object.liteMode) : undefined,
      taskCreatingTimeout: isSet(object.taskCreatingTimeout)
        ? Duration.fromJSON(object.taskCreatingTimeout)
        : undefined,
    };
  },

  toJSON(message: BackendSetting): unknown {
    const obj: any = {};
    if (message.target !== "") {
      obj.target = message.target;
    }
    if (message.hostname !== "") {
      obj.hostname = message.hostname;
    }
    if (message.pubsubId !== "") {
      obj.pubsubId = message.pubsubId;
    }
    if (message.buildSyncSetting !== undefined) {
      obj.buildSyncSetting = BackendSetting_BuildSyncSetting.toJSON(message.buildSyncSetting);
    }
    if (message.fullMode !== undefined) {
      obj.fullMode = BackendSetting_FullMode.toJSON(message.fullMode);
    }
    if (message.liteMode !== undefined) {
      obj.liteMode = BackendSetting_LiteMode.toJSON(message.liteMode);
    }
    if (message.taskCreatingTimeout !== undefined) {
      obj.taskCreatingTimeout = Duration.toJSON(message.taskCreatingTimeout);
    }
    return obj;
  },

  create(base?: DeepPartial<BackendSetting>): BackendSetting {
    return BackendSetting.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<BackendSetting>): BackendSetting {
    const message = createBaseBackendSetting() as any;
    message.target = object.target ?? "";
    message.hostname = object.hostname ?? "";
    message.pubsubId = object.pubsubId ?? "";
    message.buildSyncSetting = (object.buildSyncSetting !== undefined && object.buildSyncSetting !== null)
      ? BackendSetting_BuildSyncSetting.fromPartial(object.buildSyncSetting)
      : undefined;
    message.fullMode = (object.fullMode !== undefined && object.fullMode !== null)
      ? BackendSetting_FullMode.fromPartial(object.fullMode)
      : undefined;
    message.liteMode = (object.liteMode !== undefined && object.liteMode !== null)
      ? BackendSetting_LiteMode.fromPartial(object.liteMode)
      : undefined;
    message.taskCreatingTimeout = (object.taskCreatingTimeout !== undefined && object.taskCreatingTimeout !== null)
      ? Duration.fromPartial(object.taskCreatingTimeout)
      : undefined;
    return message;
  },
};

function createBaseBackendSetting_BuildSyncSetting(): BackendSetting_BuildSyncSetting {
  return { shards: 0, syncIntervalSeconds: 0 };
}

export const BackendSetting_BuildSyncSetting: MessageFns<BackendSetting_BuildSyncSetting> = {
  encode(message: BackendSetting_BuildSyncSetting, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.shards !== 0) {
      writer.uint32(8).int32(message.shards);
    }
    if (message.syncIntervalSeconds !== 0) {
      writer.uint32(16).int32(message.syncIntervalSeconds);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): BackendSetting_BuildSyncSetting {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseBackendSetting_BuildSyncSetting() as any;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 8) {
            break;
          }

          message.shards = reader.int32();
          continue;
        }
        case 2: {
          if (tag !== 16) {
            break;
          }

          message.syncIntervalSeconds = reader.int32();
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): BackendSetting_BuildSyncSetting {
    return {
      shards: isSet(object.shards) ? globalThis.Number(object.shards) : 0,
      syncIntervalSeconds: isSet(object.syncIntervalSeconds) ? globalThis.Number(object.syncIntervalSeconds) : 0,
    };
  },

  toJSON(message: BackendSetting_BuildSyncSetting): unknown {
    const obj: any = {};
    if (message.shards !== 0) {
      obj.shards = Math.round(message.shards);
    }
    if (message.syncIntervalSeconds !== 0) {
      obj.syncIntervalSeconds = Math.round(message.syncIntervalSeconds);
    }
    return obj;
  },

  create(base?: DeepPartial<BackendSetting_BuildSyncSetting>): BackendSetting_BuildSyncSetting {
    return BackendSetting_BuildSyncSetting.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<BackendSetting_BuildSyncSetting>): BackendSetting_BuildSyncSetting {
    const message = createBaseBackendSetting_BuildSyncSetting() as any;
    message.shards = object.shards ?? 0;
    message.syncIntervalSeconds = object.syncIntervalSeconds ?? 0;
    return message;
  },
};

function createBaseBackendSetting_FullMode(): BackendSetting_FullMode {
  return { pubsubId: "", buildSyncSetting: undefined, redirectToTaskPage: false, succeedBuildIfTaskIsSucceeded: false };
}

export const BackendSetting_FullMode: MessageFns<BackendSetting_FullMode> = {
  encode(message: BackendSetting_FullMode, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.pubsubId !== "") {
      writer.uint32(10).string(message.pubsubId);
    }
    if (message.buildSyncSetting !== undefined) {
      BackendSetting_BuildSyncSetting.encode(message.buildSyncSetting, writer.uint32(18).fork()).join();
    }
    if (message.redirectToTaskPage !== false) {
      writer.uint32(24).bool(message.redirectToTaskPage);
    }
    if (message.succeedBuildIfTaskIsSucceeded !== false) {
      writer.uint32(32).bool(message.succeedBuildIfTaskIsSucceeded);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): BackendSetting_FullMode {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseBackendSetting_FullMode() as any;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 10) {
            break;
          }

          message.pubsubId = reader.string();
          continue;
        }
        case 2: {
          if (tag !== 18) {
            break;
          }

          message.buildSyncSetting = BackendSetting_BuildSyncSetting.decode(reader, reader.uint32());
          continue;
        }
        case 3: {
          if (tag !== 24) {
            break;
          }

          message.redirectToTaskPage = reader.bool();
          continue;
        }
        case 4: {
          if (tag !== 32) {
            break;
          }

          message.succeedBuildIfTaskIsSucceeded = reader.bool();
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): BackendSetting_FullMode {
    return {
      pubsubId: isSet(object.pubsubId) ? globalThis.String(object.pubsubId) : "",
      buildSyncSetting: isSet(object.buildSyncSetting)
        ? BackendSetting_BuildSyncSetting.fromJSON(object.buildSyncSetting)
        : undefined,
      redirectToTaskPage: isSet(object.redirectToTaskPage) ? globalThis.Boolean(object.redirectToTaskPage) : false,
      succeedBuildIfTaskIsSucceeded: isSet(object.succeedBuildIfTaskIsSucceeded)
        ? globalThis.Boolean(object.succeedBuildIfTaskIsSucceeded)
        : false,
    };
  },

  toJSON(message: BackendSetting_FullMode): unknown {
    const obj: any = {};
    if (message.pubsubId !== "") {
      obj.pubsubId = message.pubsubId;
    }
    if (message.buildSyncSetting !== undefined) {
      obj.buildSyncSetting = BackendSetting_BuildSyncSetting.toJSON(message.buildSyncSetting);
    }
    if (message.redirectToTaskPage !== false) {
      obj.redirectToTaskPage = message.redirectToTaskPage;
    }
    if (message.succeedBuildIfTaskIsSucceeded !== false) {
      obj.succeedBuildIfTaskIsSucceeded = message.succeedBuildIfTaskIsSucceeded;
    }
    return obj;
  },

  create(base?: DeepPartial<BackendSetting_FullMode>): BackendSetting_FullMode {
    return BackendSetting_FullMode.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<BackendSetting_FullMode>): BackendSetting_FullMode {
    const message = createBaseBackendSetting_FullMode() as any;
    message.pubsubId = object.pubsubId ?? "";
    message.buildSyncSetting = (object.buildSyncSetting !== undefined && object.buildSyncSetting !== null)
      ? BackendSetting_BuildSyncSetting.fromPartial(object.buildSyncSetting)
      : undefined;
    message.redirectToTaskPage = object.redirectToTaskPage ?? false;
    message.succeedBuildIfTaskIsSucceeded = object.succeedBuildIfTaskIsSucceeded ?? false;
    return message;
  },
};

function createBaseBackendSetting_LiteMode(): BackendSetting_LiteMode {
  return {};
}

export const BackendSetting_LiteMode: MessageFns<BackendSetting_LiteMode> = {
  encode(_: BackendSetting_LiteMode, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): BackendSetting_LiteMode {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseBackendSetting_LiteMode() as any;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(_: any): BackendSetting_LiteMode {
    return {};
  },

  toJSON(_: BackendSetting_LiteMode): unknown {
    const obj: any = {};
    return obj;
  },

  create(base?: DeepPartial<BackendSetting_LiteMode>): BackendSetting_LiteMode {
    return BackendSetting_LiteMode.fromPartial(base ?? {});
  },
  fromPartial(_: DeepPartial<BackendSetting_LiteMode>): BackendSetting_LiteMode {
    const message = createBaseBackendSetting_LiteMode() as any;
    return message;
  },
};

function createBaseSwarmingSettings(): SwarmingSettings {
  return {
    miloHostname: "",
    globalCaches: [],
    userPackages: [],
    bbagentPackage: undefined,
    kitchenPackage: undefined,
    alternativeAgentPackages: [],
    bbagentUtilityPackages: [],
  };
}

export const SwarmingSettings: MessageFns<SwarmingSettings> = {
  encode(message: SwarmingSettings, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.miloHostname !== "") {
      writer.uint32(18).string(message.miloHostname);
    }
    for (const v of message.globalCaches) {
      BuilderConfig_CacheEntry.encode(v!, writer.uint32(34).fork()).join();
    }
    for (const v of message.userPackages) {
      SwarmingSettings_Package.encode(v!, writer.uint32(42).fork()).join();
    }
    if (message.bbagentPackage !== undefined) {
      SwarmingSettings_Package.encode(message.bbagentPackage, writer.uint32(66).fork()).join();
    }
    if (message.kitchenPackage !== undefined) {
      SwarmingSettings_Package.encode(message.kitchenPackage, writer.uint32(58).fork()).join();
    }
    for (const v of message.alternativeAgentPackages) {
      SwarmingSettings_Package.encode(v!, writer.uint32(74).fork()).join();
    }
    for (const v of message.bbagentUtilityPackages) {
      SwarmingSettings_Package.encode(v!, writer.uint32(82).fork()).join();
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): SwarmingSettings {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSwarmingSettings() as any;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 2: {
          if (tag !== 18) {
            break;
          }

          message.miloHostname = reader.string();
          continue;
        }
        case 4: {
          if (tag !== 34) {
            break;
          }

          message.globalCaches.push(BuilderConfig_CacheEntry.decode(reader, reader.uint32()));
          continue;
        }
        case 5: {
          if (tag !== 42) {
            break;
          }

          message.userPackages.push(SwarmingSettings_Package.decode(reader, reader.uint32()));
          continue;
        }
        case 8: {
          if (tag !== 66) {
            break;
          }

          message.bbagentPackage = SwarmingSettings_Package.decode(reader, reader.uint32());
          continue;
        }
        case 7: {
          if (tag !== 58) {
            break;
          }

          message.kitchenPackage = SwarmingSettings_Package.decode(reader, reader.uint32());
          continue;
        }
        case 9: {
          if (tag !== 74) {
            break;
          }

          message.alternativeAgentPackages.push(SwarmingSettings_Package.decode(reader, reader.uint32()));
          continue;
        }
        case 10: {
          if (tag !== 82) {
            break;
          }

          message.bbagentUtilityPackages.push(SwarmingSettings_Package.decode(reader, reader.uint32()));
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): SwarmingSettings {
    return {
      miloHostname: isSet(object.miloHostname) ? globalThis.String(object.miloHostname) : "",
      globalCaches: globalThis.Array.isArray(object?.globalCaches)
        ? object.globalCaches.map((e: any) => BuilderConfig_CacheEntry.fromJSON(e))
        : [],
      userPackages: globalThis.Array.isArray(object?.userPackages)
        ? object.userPackages.map((e: any) => SwarmingSettings_Package.fromJSON(e))
        : [],
      bbagentPackage: isSet(object.bbagentPackage)
        ? SwarmingSettings_Package.fromJSON(object.bbagentPackage)
        : undefined,
      kitchenPackage: isSet(object.kitchenPackage)
        ? SwarmingSettings_Package.fromJSON(object.kitchenPackage)
        : undefined,
      alternativeAgentPackages: globalThis.Array.isArray(object?.alternativeAgentPackages)
        ? object.alternativeAgentPackages.map((e: any) => SwarmingSettings_Package.fromJSON(e))
        : [],
      bbagentUtilityPackages: globalThis.Array.isArray(object?.bbagentUtilityPackages)
        ? object.bbagentUtilityPackages.map((e: any) => SwarmingSettings_Package.fromJSON(e))
        : [],
    };
  },

  toJSON(message: SwarmingSettings): unknown {
    const obj: any = {};
    if (message.miloHostname !== "") {
      obj.miloHostname = message.miloHostname;
    }
    if (message.globalCaches?.length) {
      obj.globalCaches = message.globalCaches.map((e) => BuilderConfig_CacheEntry.toJSON(e));
    }
    if (message.userPackages?.length) {
      obj.userPackages = message.userPackages.map((e) => SwarmingSettings_Package.toJSON(e));
    }
    if (message.bbagentPackage !== undefined) {
      obj.bbagentPackage = SwarmingSettings_Package.toJSON(message.bbagentPackage);
    }
    if (message.kitchenPackage !== undefined) {
      obj.kitchenPackage = SwarmingSettings_Package.toJSON(message.kitchenPackage);
    }
    if (message.alternativeAgentPackages?.length) {
      obj.alternativeAgentPackages = message.alternativeAgentPackages.map((e) => SwarmingSettings_Package.toJSON(e));
    }
    if (message.bbagentUtilityPackages?.length) {
      obj.bbagentUtilityPackages = message.bbagentUtilityPackages.map((e) => SwarmingSettings_Package.toJSON(e));
    }
    return obj;
  },

  create(base?: DeepPartial<SwarmingSettings>): SwarmingSettings {
    return SwarmingSettings.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<SwarmingSettings>): SwarmingSettings {
    const message = createBaseSwarmingSettings() as any;
    message.miloHostname = object.miloHostname ?? "";
    message.globalCaches = object.globalCaches?.map((e) => BuilderConfig_CacheEntry.fromPartial(e)) || [];
    message.userPackages = object.userPackages?.map((e) => SwarmingSettings_Package.fromPartial(e)) || [];
    message.bbagentPackage = (object.bbagentPackage !== undefined && object.bbagentPackage !== null)
      ? SwarmingSettings_Package.fromPartial(object.bbagentPackage)
      : undefined;
    message.kitchenPackage = (object.kitchenPackage !== undefined && object.kitchenPackage !== null)
      ? SwarmingSettings_Package.fromPartial(object.kitchenPackage)
      : undefined;
    message.alternativeAgentPackages =
      object.alternativeAgentPackages?.map((e) => SwarmingSettings_Package.fromPartial(e)) || [];
    message.bbagentUtilityPackages =
      object.bbagentUtilityPackages?.map((e) => SwarmingSettings_Package.fromPartial(e)) || [];
    return message;
  },
};

function createBaseSwarmingSettings_Package(): SwarmingSettings_Package {
  return {
    packageName: "",
    version: "",
    versionCanary: "",
    builders: undefined,
    subdir: "",
    omitOnExperiment: [],
    includeOnExperiment: [],
  };
}

export const SwarmingSettings_Package: MessageFns<SwarmingSettings_Package> = {
  encode(message: SwarmingSettings_Package, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.packageName !== "") {
      writer.uint32(10).string(message.packageName);
    }
    if (message.version !== "") {
      writer.uint32(18).string(message.version);
    }
    if (message.versionCanary !== "") {
      writer.uint32(26).string(message.versionCanary);
    }
    if (message.builders !== undefined) {
      BuilderPredicate.encode(message.builders, writer.uint32(34).fork()).join();
    }
    if (message.subdir !== "") {
      writer.uint32(42).string(message.subdir);
    }
    for (const v of message.omitOnExperiment) {
      writer.uint32(50).string(v!);
    }
    for (const v of message.includeOnExperiment) {
      writer.uint32(58).string(v!);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): SwarmingSettings_Package {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSwarmingSettings_Package() as any;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 10) {
            break;
          }

          message.packageName = reader.string();
          continue;
        }
        case 2: {
          if (tag !== 18) {
            break;
          }

          message.version = reader.string();
          continue;
        }
        case 3: {
          if (tag !== 26) {
            break;
          }

          message.versionCanary = reader.string();
          continue;
        }
        case 4: {
          if (tag !== 34) {
            break;
          }

          message.builders = BuilderPredicate.decode(reader, reader.uint32());
          continue;
        }
        case 5: {
          if (tag !== 42) {
            break;
          }

          message.subdir = reader.string();
          continue;
        }
        case 6: {
          if (tag !== 50) {
            break;
          }

          message.omitOnExperiment.push(reader.string());
          continue;
        }
        case 7: {
          if (tag !== 58) {
            break;
          }

          message.includeOnExperiment.push(reader.string());
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): SwarmingSettings_Package {
    return {
      packageName: isSet(object.packageName) ? globalThis.String(object.packageName) : "",
      version: isSet(object.version) ? globalThis.String(object.version) : "",
      versionCanary: isSet(object.versionCanary) ? globalThis.String(object.versionCanary) : "",
      builders: isSet(object.builders) ? BuilderPredicate.fromJSON(object.builders) : undefined,
      subdir: isSet(object.subdir) ? globalThis.String(object.subdir) : "",
      omitOnExperiment: globalThis.Array.isArray(object?.omitOnExperiment)
        ? object.omitOnExperiment.map((e: any) => globalThis.String(e))
        : [],
      includeOnExperiment: globalThis.Array.isArray(object?.includeOnExperiment)
        ? object.includeOnExperiment.map((e: any) => globalThis.String(e))
        : [],
    };
  },

  toJSON(message: SwarmingSettings_Package): unknown {
    const obj: any = {};
    if (message.packageName !== "") {
      obj.packageName = message.packageName;
    }
    if (message.version !== "") {
      obj.version = message.version;
    }
    if (message.versionCanary !== "") {
      obj.versionCanary = message.versionCanary;
    }
    if (message.builders !== undefined) {
      obj.builders = BuilderPredicate.toJSON(message.builders);
    }
    if (message.subdir !== "") {
      obj.subdir = message.subdir;
    }
    if (message.omitOnExperiment?.length) {
      obj.omitOnExperiment = message.omitOnExperiment;
    }
    if (message.includeOnExperiment?.length) {
      obj.includeOnExperiment = message.includeOnExperiment;
    }
    return obj;
  },

  create(base?: DeepPartial<SwarmingSettings_Package>): SwarmingSettings_Package {
    return SwarmingSettings_Package.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<SwarmingSettings_Package>): SwarmingSettings_Package {
    const message = createBaseSwarmingSettings_Package() as any;
    message.packageName = object.packageName ?? "";
    message.version = object.version ?? "";
    message.versionCanary = object.versionCanary ?? "";
    message.builders = (object.builders !== undefined && object.builders !== null)
      ? BuilderPredicate.fromPartial(object.builders)
      : undefined;
    message.subdir = object.subdir ?? "";
    message.omitOnExperiment = object.omitOnExperiment?.map((e) => e) || [];
    message.includeOnExperiment = object.includeOnExperiment?.map((e) => e) || [];
    return message;
  },
};

function createBaseLogDogSettings(): LogDogSettings {
  return { hostname: "" };
}

export const LogDogSettings: MessageFns<LogDogSettings> = {
  encode(message: LogDogSettings, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.hostname !== "") {
      writer.uint32(10).string(message.hostname);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): LogDogSettings {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseLogDogSettings() as any;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 10) {
            break;
          }

          message.hostname = reader.string();
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): LogDogSettings {
    return { hostname: isSet(object.hostname) ? globalThis.String(object.hostname) : "" };
  },

  toJSON(message: LogDogSettings): unknown {
    const obj: any = {};
    if (message.hostname !== "") {
      obj.hostname = message.hostname;
    }
    return obj;
  },

  create(base?: DeepPartial<LogDogSettings>): LogDogSettings {
    return LogDogSettings.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<LogDogSettings>): LogDogSettings {
    const message = createBaseLogDogSettings() as any;
    message.hostname = object.hostname ?? "";
    return message;
  },
};

function createBaseExperimentSettings(): ExperimentSettings {
  return { experiments: [] };
}

export const ExperimentSettings: MessageFns<ExperimentSettings> = {
  encode(message: ExperimentSettings, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    for (const v of message.experiments) {
      ExperimentSettings_Experiment.encode(v!, writer.uint32(10).fork()).join();
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): ExperimentSettings {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseExperimentSettings() as any;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 10) {
            break;
          }

          message.experiments.push(ExperimentSettings_Experiment.decode(reader, reader.uint32()));
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ExperimentSettings {
    return {
      experiments: globalThis.Array.isArray(object?.experiments)
        ? object.experiments.map((e: any) => ExperimentSettings_Experiment.fromJSON(e))
        : [],
    };
  },

  toJSON(message: ExperimentSettings): unknown {
    const obj: any = {};
    if (message.experiments?.length) {
      obj.experiments = message.experiments.map((e) => ExperimentSettings_Experiment.toJSON(e));
    }
    return obj;
  },

  create(base?: DeepPartial<ExperimentSettings>): ExperimentSettings {
    return ExperimentSettings.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<ExperimentSettings>): ExperimentSettings {
    const message = createBaseExperimentSettings() as any;
    message.experiments = object.experiments?.map((e) => ExperimentSettings_Experiment.fromPartial(e)) || [];
    return message;
  },
};

function createBaseExperimentSettings_Experiment(): ExperimentSettings_Experiment {
  return { name: "", defaultValue: 0, minimumValue: 0, builders: undefined, inactive: false };
}

export const ExperimentSettings_Experiment: MessageFns<ExperimentSettings_Experiment> = {
  encode(message: ExperimentSettings_Experiment, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.name !== "") {
      writer.uint32(10).string(message.name);
    }
    if (message.defaultValue !== 0) {
      writer.uint32(16).int32(message.defaultValue);
    }
    if (message.minimumValue !== 0) {
      writer.uint32(24).int32(message.minimumValue);
    }
    if (message.builders !== undefined) {
      BuilderPredicate.encode(message.builders, writer.uint32(34).fork()).join();
    }
    if (message.inactive !== false) {
      writer.uint32(40).bool(message.inactive);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): ExperimentSettings_Experiment {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseExperimentSettings_Experiment() as any;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 10) {
            break;
          }

          message.name = reader.string();
          continue;
        }
        case 2: {
          if (tag !== 16) {
            break;
          }

          message.defaultValue = reader.int32();
          continue;
        }
        case 3: {
          if (tag !== 24) {
            break;
          }

          message.minimumValue = reader.int32();
          continue;
        }
        case 4: {
          if (tag !== 34) {
            break;
          }

          message.builders = BuilderPredicate.decode(reader, reader.uint32());
          continue;
        }
        case 5: {
          if (tag !== 40) {
            break;
          }

          message.inactive = reader.bool();
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ExperimentSettings_Experiment {
    return {
      name: isSet(object.name) ? globalThis.String(object.name) : "",
      defaultValue: isSet(object.defaultValue) ? globalThis.Number(object.defaultValue) : 0,
      minimumValue: isSet(object.minimumValue) ? globalThis.Number(object.minimumValue) : 0,
      builders: isSet(object.builders) ? BuilderPredicate.fromJSON(object.builders) : undefined,
      inactive: isSet(object.inactive) ? globalThis.Boolean(object.inactive) : false,
    };
  },

  toJSON(message: ExperimentSettings_Experiment): unknown {
    const obj: any = {};
    if (message.name !== "") {
      obj.name = message.name;
    }
    if (message.defaultValue !== 0) {
      obj.defaultValue = Math.round(message.defaultValue);
    }
    if (message.minimumValue !== 0) {
      obj.minimumValue = Math.round(message.minimumValue);
    }
    if (message.builders !== undefined) {
      obj.builders = BuilderPredicate.toJSON(message.builders);
    }
    if (message.inactive !== false) {
      obj.inactive = message.inactive;
    }
    return obj;
  },

  create(base?: DeepPartial<ExperimentSettings_Experiment>): ExperimentSettings_Experiment {
    return ExperimentSettings_Experiment.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<ExperimentSettings_Experiment>): ExperimentSettings_Experiment {
    const message = createBaseExperimentSettings_Experiment() as any;
    message.name = object.name ?? "";
    message.defaultValue = object.defaultValue ?? 0;
    message.minimumValue = object.minimumValue ?? 0;
    message.builders = (object.builders !== undefined && object.builders !== null)
      ? BuilderPredicate.fromPartial(object.builders)
      : undefined;
    message.inactive = object.inactive ?? false;
    return message;
  },
};

function createBaseBuilderPredicate(): BuilderPredicate {
  return { regex: [], regexExclude: [] };
}

export const BuilderPredicate: MessageFns<BuilderPredicate> = {
  encode(message: BuilderPredicate, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    for (const v of message.regex) {
      writer.uint32(10).string(v!);
    }
    for (const v of message.regexExclude) {
      writer.uint32(18).string(v!);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): BuilderPredicate {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseBuilderPredicate() as any;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 10) {
            break;
          }

          message.regex.push(reader.string());
          continue;
        }
        case 2: {
          if (tag !== 18) {
            break;
          }

          message.regexExclude.push(reader.string());
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): BuilderPredicate {
    return {
      regex: globalThis.Array.isArray(object?.regex) ? object.regex.map((e: any) => globalThis.String(e)) : [],
      regexExclude: globalThis.Array.isArray(object?.regexExclude)
        ? object.regexExclude.map((e: any) => globalThis.String(e))
        : [],
    };
  },

  toJSON(message: BuilderPredicate): unknown {
    const obj: any = {};
    if (message.regex?.length) {
      obj.regex = message.regex;
    }
    if (message.regexExclude?.length) {
      obj.regexExclude = message.regexExclude;
    }
    return obj;
  },

  create(base?: DeepPartial<BuilderPredicate>): BuilderPredicate {
    return BuilderPredicate.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<BuilderPredicate>): BuilderPredicate {
    const message = createBaseBuilderPredicate() as any;
    message.regex = object.regex?.map((e) => e) || [];
    message.regexExclude = object.regexExclude?.map((e) => e) || [];
    return message;
  },
};

function createBaseResultDBSettings(): ResultDBSettings {
  return { hostname: "" };
}

export const ResultDBSettings: MessageFns<ResultDBSettings> = {
  encode(message: ResultDBSettings, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.hostname !== "") {
      writer.uint32(10).string(message.hostname);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): ResultDBSettings {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseResultDBSettings() as any;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 10) {
            break;
          }

          message.hostname = reader.string();
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ResultDBSettings {
    return { hostname: isSet(object.hostname) ? globalThis.String(object.hostname) : "" };
  },

  toJSON(message: ResultDBSettings): unknown {
    const obj: any = {};
    if (message.hostname !== "") {
      obj.hostname = message.hostname;
    }
    return obj;
  },

  create(base?: DeepPartial<ResultDBSettings>): ResultDBSettings {
    return ResultDBSettings.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<ResultDBSettings>): ResultDBSettings {
    const message = createBaseResultDBSettings() as any;
    message.hostname = object.hostname ?? "";
    return message;
  },
};

function createBaseCipdSettings(): CipdSettings {
  return { server: "", source: undefined };
}

export const CipdSettings: MessageFns<CipdSettings> = {
  encode(message: CipdSettings, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.server !== "") {
      writer.uint32(10).string(message.server);
    }
    if (message.source !== undefined) {
      CipdSettings_Source.encode(message.source, writer.uint32(18).fork()).join();
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): CipdSettings {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCipdSettings() as any;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 10) {
            break;
          }

          message.server = reader.string();
          continue;
        }
        case 2: {
          if (tag !== 18) {
            break;
          }

          message.source = CipdSettings_Source.decode(reader, reader.uint32());
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): CipdSettings {
    return {
      server: isSet(object.server) ? globalThis.String(object.server) : "",
      source: isSet(object.source) ? CipdSettings_Source.fromJSON(object.source) : undefined,
    };
  },

  toJSON(message: CipdSettings): unknown {
    const obj: any = {};
    if (message.server !== "") {
      obj.server = message.server;
    }
    if (message.source !== undefined) {
      obj.source = CipdSettings_Source.toJSON(message.source);
    }
    return obj;
  },

  create(base?: DeepPartial<CipdSettings>): CipdSettings {
    return CipdSettings.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<CipdSettings>): CipdSettings {
    const message = createBaseCipdSettings() as any;
    message.server = object.server ?? "";
    message.source = (object.source !== undefined && object.source !== null)
      ? CipdSettings_Source.fromPartial(object.source)
      : undefined;
    return message;
  },
};

function createBaseCipdSettings_Source(): CipdSettings_Source {
  return { packageName: "", version: "", versionCanary: "" };
}

export const CipdSettings_Source: MessageFns<CipdSettings_Source> = {
  encode(message: CipdSettings_Source, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.packageName !== "") {
      writer.uint32(18).string(message.packageName);
    }
    if (message.version !== "") {
      writer.uint32(26).string(message.version);
    }
    if (message.versionCanary !== "") {
      writer.uint32(34).string(message.versionCanary);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): CipdSettings_Source {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCipdSettings_Source() as any;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 2: {
          if (tag !== 18) {
            break;
          }

          message.packageName = reader.string();
          continue;
        }
        case 3: {
          if (tag !== 26) {
            break;
          }

          message.version = reader.string();
          continue;
        }
        case 4: {
          if (tag !== 34) {
            break;
          }

          message.versionCanary = reader.string();
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): CipdSettings_Source {
    return {
      packageName: isSet(object.packageName) ? globalThis.String(object.packageName) : "",
      version: isSet(object.version) ? globalThis.String(object.version) : "",
      versionCanary: isSet(object.versionCanary) ? globalThis.String(object.versionCanary) : "",
    };
  },

  toJSON(message: CipdSettings_Source): unknown {
    const obj: any = {};
    if (message.packageName !== "") {
      obj.packageName = message.packageName;
    }
    if (message.version !== "") {
      obj.version = message.version;
    }
    if (message.versionCanary !== "") {
      obj.versionCanary = message.versionCanary;
    }
    return obj;
  },

  create(base?: DeepPartial<CipdSettings_Source>): CipdSettings_Source {
    return CipdSettings_Source.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<CipdSettings_Source>): CipdSettings_Source {
    const message = createBaseCipdSettings_Source() as any;
    message.packageName = object.packageName ?? "";
    message.version = object.version ?? "";
    message.versionCanary = object.versionCanary ?? "";
    return message;
  },
};

function createBaseCustomMetric(): CustomMetric {
  return { name: "", description: "", extraFields: [], metricBase: undefined };
}

export const CustomMetric: MessageFns<CustomMetric> = {
  encode(message: CustomMetric, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.name !== "") {
      writer.uint32(10).string(message.name);
    }
    if (message.description !== "") {
      writer.uint32(18).string(message.description);
    }
    for (const v of message.extraFields) {
      writer.uint32(26).string(v!);
    }
    if (message.metricBase !== undefined) {
      writer.uint32(32).int32(message.metricBase);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): CustomMetric {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCustomMetric() as any;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 10) {
            break;
          }

          message.name = reader.string();
          continue;
        }
        case 2: {
          if (tag !== 18) {
            break;
          }

          message.description = reader.string();
          continue;
        }
        case 3: {
          if (tag !== 26) {
            break;
          }

          message.extraFields.push(reader.string());
          continue;
        }
        case 4: {
          if (tag !== 32) {
            break;
          }

          message.metricBase = reader.int32() as any;
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): CustomMetric {
    return {
      name: isSet(object.name) ? globalThis.String(object.name) : "",
      description: isSet(object.description) ? globalThis.String(object.description) : "",
      extraFields: globalThis.Array.isArray(object?.extraFields)
        ? object.extraFields.map((e: any) => globalThis.String(e))
        : [],
      metricBase: isSet(object.metricBase) ? customMetricBaseFromJSON(object.metricBase) : undefined,
    };
  },

  toJSON(message: CustomMetric): unknown {
    const obj: any = {};
    if (message.name !== "") {
      obj.name = message.name;
    }
    if (message.description !== "") {
      obj.description = message.description;
    }
    if (message.extraFields?.length) {
      obj.extraFields = message.extraFields;
    }
    if (message.metricBase !== undefined) {
      obj.metricBase = customMetricBaseToJSON(message.metricBase);
    }
    return obj;
  },

  create(base?: DeepPartial<CustomMetric>): CustomMetric {
    return CustomMetric.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<CustomMetric>): CustomMetric {
    const message = createBaseCustomMetric() as any;
    message.name = object.name ?? "";
    message.description = object.description ?? "";
    message.extraFields = object.extraFields?.map((e) => e) || [];
    message.metricBase = object.metricBase ?? undefined;
    return message;
  },
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends globalThis.Array<infer U> ? globalThis.Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

function isObject(value: any): boolean {
  return typeof value === "object" && value !== null;
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}

export interface MessageFns<T> {
  encode(message: T, writer?: BinaryWriter): BinaryWriter;
  decode(input: BinaryReader | Uint8Array, length?: number): T;
  fromJSON(object: any): T;
  toJSON(message: T): unknown;
  create(base?: DeepPartial<T>): T;
  fromPartial(object: DeepPartial<T>): T;
}
